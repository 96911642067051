import { createContext } from "react";
import { buyer, FlowData, PayProps, visitor } from '../interfaces/interfaces';


interface Props {
    dataState: FlowData;
    getSlots: () => Promise<void>;
    getReserve: any;
    getRegiones: () => Promise<void>;
    cleanReserve: () => void;
    getDayHour: (day: string, hour: string) => void;
    setVisitor: (visitor: visitor) => void;
    setBuyer: (buyer: buyer) => void; 
    cleanBuyer: () => void;
    getInvoice: (id: string) => Promise<void>;
    setPayment: (data: any) => Promise<void>;
    setPayIntention: (payIntention: PayProps, date: string, visitor: any, lang: string, visitantes: any) => Promise<void | boolean>;
    setPayData: (id: string) => Promise<void>;
    setTermsAndConditions: (buyer: buyer) => void;
    getHolidays: () => Promise<any>;
    getGenerarHorarios: (day: string, month: string) => Promise<any>;
    getDocumentpdf: (document: any) => Promise<any>;
    getTrxByDate: (date: string) => Promise<any>;
    getTicketActive: (date: string) => Promise<any>;
    getTicketMaxFunction: (date: string, idticket: number) => Promise<any>;
    setVisitantes: (visitor: any) => void;
}

export const DataContext = createContext<Props>( {} as Props );