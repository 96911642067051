/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment-timezone";
import "moment/locale/es";
import { DataContext } from "../context/DataContext";
import { FaRegClock, FaRegCalendar } from "react-icons/fa";
import ReactPixel from "react-facebook-pixel";
import { language } from "../helpers/lang";
import { reserveSlotTemp } from "../helpers/reserveSlot";

import Loadimage from "../assets/img/load_sky.gif";

type StatusType = "loading" | "noboleta" | "failed" | "rejected" | "approved";

export const CheckoutPage = () => {
  type T = keyof typeof language;

  const idioma = useParams();
  const lang: string = idioma.lang || "es";

  const {
    dataState,
    setPayData,
    getReserve,
    setBuyer,
    setPayment,
    getDayHour,
    getDocumentpdf,
    setVisitantes,
    getTicketActive,
  } = useContext(DataContext);

  const { trxResponse, buyer, invoice, slotSelected, hour } = dataState;

  const navigate = useNavigate();

  //Estados Locales
  const [describe, setDescribe] = useState("");
  const [cantidad, setCantidad] = useState(0);
  const [folio, setFolio] = useState("");
  const [tickets, setTickets] = useState([] as any);

  //Estados de Flujo
  const [status, setStatus] = useState("loading");
  const [pay, setPay] = useState(false);
  const [correctDay, setCorrectDay] = useState("");

  const tab = <>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
  const hash = window.location.hash.replace("#", "");

  useEffect(() => {
    getTicketActivos();
    validarTimePage();
    handleHashParams();
  }, []);

  useEffect(() => {
    if (!trxResponse) {
      // Aún no hay respuesta => seguimos en 'loading'
      return;
    }

    if (trxResponse.state === "processing") {
      setStatus("loading");
      return;
    }

    // REJECTED
    if (trxResponse.state === "REJECTED") {
      setStatus("rejected");
    }

    // Si hay algún dato en la respuesta
    if (Object.keys(trxResponse).length > 0) {
      // Manejo "exitoso" o "noboleta"
      handleSuccessfulTransaction();
    } else {
      // Si llegó vacío, lo tomamos como fallo
      setStatus("failed");
    }
  }, [trxResponse, tickets]);

  useEffect(() => {
    if (trxResponse && trxResponse.fiscaldocnumber) {
      setFolio(trxResponse.fiscaldocnumber);
    }
  }, [trxResponse]);

  useEffect(() => {
    if (trxResponse && trxResponse.fiscaldocnumber) {
      setFolio(trxResponse.fiscaldocnumber);
    }
  }, [trxResponse]);

  const handleHashParams = () => {
    const parseParms = (str: string) => {
      const pieces = str.split("&");
      const data: { [key: string]: string } = {};

      for (let i = 0; i < pieces.length; i++) {
        let parts = pieces[i].split("=");
        if (parts.length < 2) {
          parts.push("");
        }
        data[decodeURIComponent(parts[0])] = decodeURIComponent(parts[1]);
      }
      return data;
    };

    const objectHash = parseParms(hash);

    if (objectHash.state !== "failed") {
      setStatus(objectHash.state as StatusType);
      setPayData(objectHash.id);
    } else {
      setStatus("failed");
      setTimeout(() => {
        navigate(`/${lang}/`);
      }, 5000);
    }
  };

  const handleRejectedTransaction = () => {
    const dayIso = `${trxResponse.dataTrx.date}T${trxResponse.dataTrx.hour}:00.000Z`;
    const timeZone = process.env.REACT_APP_TIMEZONE || "America/Santiago";
    const backDate = moment.tz(dayIso, timeZone);
    const backDateDay = backDate.format("YYYY-MM-DD");
    const backDateTime = backDate.format("HH:mm");

    getDayHour(backDateDay, backDateTime);
    const reserve = reserveSlotTemp(
      backDateDay,
      parseInt(backDateTime.split(":")[0])
    );
    getReserve(reserve);

    const arrayDetails = trxResponse.dataTrx.trx_details;
    let visitantes: any[] = [];
    if (tickets && arrayDetails && arrayDetails.length > 0) {
      arrayDetails.forEach((ticket: any) => {
        const idTicket = parseInt(ticket.id_ticket);
        const ticketEncontrado = tickets.filter((t: any) => t.id === idTicket);

        visitantes.push({
          ...ticketEncontrado[0],
          valor: ticket.price,
          count: ticket.quantity,
        });
      });
    }

    setVisitantes(visitantes);

    const buyerData = {
      name: trxResponse.dataTrx.name,
      lastname: trxResponse.dataTrx.last_name,
      email: trxResponse.dataTrx.email,
      phone: trxResponse.dataTrx.phone,
      residencia: trxResponse.dataTrx.resident,
      nation: trxResponse.dataTrx.nation,
      rut: trxResponse.dataTrx.rut,
      dni: trxResponse.dataTrx.dni || trxResponse.dataTrx.rut,
      birthday: trxResponse.dataTrx.birthday,
      promo: trxResponse.dataTrx.informate,
      region: trxResponse.dataTrx.region,
    };

    setBuyer(buyerData);
    setTimeout(() => {
      navigate(`/${lang}/pay`);
    }, 5000);
  };

  const handleSuccessfulTransaction = () => {
    // Si venía REJECTED, se maneja aparte
    if (trxResponse.state === "REJECTED") {
      handleRejectedTransaction();
      ReactPixel.track("Purchase", {
        content_ids: ["REJECTED"],
      });
      return;
    }

    setDescribe("");
    setCantidad(0);

    trxResponse.trx_details.forEach((det: any) => {
      if (det.quantity > 0) {
        setDescribe(
          (prev) => prev + " " + det.quantity + " " + det.description + " + "
        );
        setCantidad((prev) => prev + det.quantity);
      }
    });

    // const tickets = trxResponse.trx_details;

    // Facebook Pixel
    const ticketsArr = trxResponse.trx_details;
    ReactPixel.track("Purchase", {
      content_ids: ticketsArr.map((ticket: any) => ticket.id_ticket),
      contents: ticketsArr.map((ticket: any) => ({
        id: ticket.id_ticket,
        quantity: ticket.quantity,
      })),
      currency: "CLP",
      value: trxResponse.total,
    });

    const dayIso = `${trxResponse.date}T${trxResponse.hour}:00.000Z`;
    const timeZone = process.env.REACT_APP_TIMEZONE || "America/Santiago";
    const backDate = moment.tz(dayIso, timeZone);
    const backDateDay = backDate.format("YYYY-MM-DD");
    const backDateTime = backDate.format("HH:mm");

    getDayHour(backDateDay, backDateTime);

    const reserve = reserveSlotTemp(
      backDateDay,
      parseInt(backDateTime.split(":")[0])
    );
    getReserve(reserve);

    const diaCorregido = backDate.format("YYYY-MM-DD HH:mm");
    setCorrectDay(diaCorregido);

    setBuyer({
      name: trxResponse.name,
      lastname: trxResponse.last_name,
      email: trxResponse.email,
      phone: trxResponse.phone,
      residencia: trxResponse.resident,
      nation: trxResponse.nation,
      rut: trxResponse.rut,
      dni: trxResponse.dni || trxResponse.rut,
      birthday: trxResponse.birthday,
      promo: trxResponse.informate,
      region: trxResponse.region,
    });

    setDescribe((prev) => prev.substring(0, prev.length - 2));
    //setStatus(trxResponse.status);
    // Verificar si la boleta existe
    if (!trxResponse.fiscaldocnumber) {
      // Esto indica que se cobró pero no se generó boleta
      setStatus("noboleta");
    } else {
      // Pago OK y boleta generada
      setFolio(trxResponse.fiscaldocnumber);
      setStatus("approved");
    }

    if (trxResponse.state === "REJECTED") {
      setTimeout(() => {
        navigate(`/${lang}/pay`);
      }, 5000);
    } else if (!pay) {
      const dataPay = {
        trx: trxResponse.id,
        posno: "9999",
        terminaltype: 3,
        terminal: "WEB",
        paytype: 3,
        payflow: "ONLINE",
        paybrand: trxResponse.tbkcardname,
        amount: trxResponse.total,
        fecha: trxResponse.datetrx,
        authcode: trxResponse.tbkauthcode,
        authdate: trxResponse.datetrx,
        lastfour: trxResponse.tbkcardnumber,
      };
      setPayment(dataPay);
      setPay(true);
    }
  };

  const validarTimePage = () => {
    const timePage = localStorage.getItem("timePage");
    if (timePage === null) {
      window.location.href = "/es";
      return;
    }

    const fechaActual = new Date();
    const fechaDefinida = new Date(timePage!.toString());
    const diferenciaEnMilisegundos =
      fechaActual.getTime() - fechaDefinida.getTime();
    const diferenciaEnMinutos = Math.floor(
      diferenciaEnMilisegundos / (1000 * 60)
    );

    if (diferenciaEnMinutos >= 30) {
      localStorage.clear();
      window.location.href = "/es";
    }
  };

  const dataPdf = async (url: string) => {
    const pdf = await getDocumentpdf({ url: url });
    const getPdf = await pdf.blob();
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(getPdf);
    link.download = "boleta.pdf";
    link.click();
    window.URL.revokeObjectURL(link.href);
  };

  const handleBack = () => {
    navigate(`/${lang}/`);
  };

  const printDocument = () => {
    if (invoice && invoice.create) {
      dataPdf(invoice.urlPdf);
    }
  };

  const getTicketActivos = async () => {
    const listTickets = await getTicketActive(new Date().toISOString());
    seleccionarTipoTicket(listTickets);
  };

  const seleccionarTipoTicket = (listTickets: any) => {
    if (!listTickets) {
      return [];
    }
    let ticketsSeleccionados = listTickets
      .filter((ticket: any) => ticket.name.includes("/web"))
      .map((ticket: any) => ({
        ...ticket,
        name: ticket.name.split("/")[0],
        count: 0,
      }));

    setTickets(ticketsSeleccionados);
  };

  // 1) LOADING
  if (status === "loading") {
    return (
      <div className="title container-fluid">
        <h3>ESTAMOS PROCESANDO TU OPERACIÓN</h3>
        <p className="mt-4">Espere un momento.</p>
        <div className="d-flex justify-content-center align-items-center p-5">
          <img src={Loadimage} alt="Cargando" />
        </div>
      </div>
    );
  }

  // 2) NO BOLETA => pago OK pero sin fiscaldocnumber
  if (status === "noboleta") {
    return (
      <div className="title container-fluid">
        <h3>¡Pago Recibido!</h3>
        <p className="mt-4">
          Tu pago se ha realizado correctamente, pero tuvimos un inconveniente
          para generar tu boleta. Enviaremos la boleta al correo{" "}
          <b>{buyer.email}</b> tan pronto esté disponible.
        </p>
        <button onClick={handleBack} className="btn-form mt-4">
          {language[lang as T].checkoutInicio}
        </button>
      </div>
    );
  }

  // 3) REJECTED => transacción rechazada
  if (status === "rejected") {
    return (
      <div className="title container-fluid">
        <h4>HEMOS TENIDO PROBLEMAS CON TU PAGO, INTÉNTALO NUEVAMENTE</h4>
        <p className="mt-4">
          El sitio te redirigirá a la sección de pago para que lo reintentes.
        </p>
      </div>
    );
  }

  // 4) FAILED => error genérico
  if (status === "failed") {
    return (
      <div className="title container-fluid">
        <h3>Ocurrió un problema</h3>
        <p className="mt-4">
          No se pudo procesar la transacción. Intenta nuevamente más tarde.
        </p>
        <button onClick={handleBack} className="btn-form mt-4">
          Volver al Inicio
        </button>
      </div>
    );
  }

  // 5) APPROVED => Pago OK con boleta generada
  return (
    <>
      <div className="title container-fluid">
        <h3>{language[lang as T].checkoutTitle}</h3>
        <p className="mt-4">{language[lang as T].checkoutDesc}</p>
      </div>
      <div className="box_main container-fluid mt-3">
        <div className="box-container">
          <div className="box_form mb-5">
            <form
              className="d-block"
              onSubmit={() => {
                console.log("exito");
              }}
            >
              <div className="row">
                <div className="col-md">
                  <h4>{language[lang as T].resumeCompra}</h4>
                </div>
                <div className="col-md text-end">
                  {/* Ejemplo: extraer algún número de la trx */}
                  <h4>
                    {language[lang as T].checkoutTrx} {tab}{" "}
                    {trxResponse.id.split("-")[4]}
                  </h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  <div className="input_form input_date">
                    <FaRegCalendar className="me-2" />
                    <input
                      className="input-inside"
                      value={
                        moment(slotSelected.start)
                          .format("dddd D MMMM YYYY")
                          .toLocaleString()!
                      }
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-md">
                  <div className="input_form input_date">
                    <FaRegClock className="me-2" />
                    <input className="input-inside" value={hour} readOnly />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="d-block p-2">
                  <div className="row wborder back-primary mb-3 ms-1">
                    <div className="col-md-2">
                      <h5 className="mb-0">Total</h5>
                    </div>
                    <div className="col-md-3">
                      <strong>
                        {correctDay.split(" ")[0] +
                          " - " +
                          correctDay.split(" ")[1] +
                          "hrs"}
                      </strong>
                    </div>
                    <div className="col-md-3">
                      <strong>{describe}</strong>
                    </div>
                    <div className="col-md text-end">
                      <strong>
                        {"$ " + trxResponse.total.toLocaleString() + " CLP"}
                      </strong>
                    </div>
                    <div className="col-md text-end">
                      <button className="btn_counter btn-inv" disabled={true}>
                        -
                      </button>
                      <input
                        className="counter text-center back-primary"
                        value={cantidad}
                        readOnly
                      />
                      <button className="btn_counter btn-inv" disabled={true}>
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Warning Devoluciones */}
              <div className="row mb-3">
                <h5>* {language[lang as T].payReturn}</h5>
              </div>

              <div className="row mb-1">
                <h4 className="mt-4">{language[lang as T].checkoutMedio}</h4>
              </div>
              <div>
                <div className="row">
                  <div>
                    <div className="input_form w-100 d-flex justify-content-between align-items-center">
                      <span>Web Pay</span>
                      <img
                        className="img_trj"
                        src={require("../assets/img/BotonWebPay-img.jpg")}
                        alt="Web Pay"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row mb-1">
                <h4 className="mt-4">{language[lang as T].checkoutBoleta}</h4>
              </div>
              <div className="row">
                <div>
                  <div className="input_form w-100 d-flex justify-content-between align-items-center">
                    {folio !== "" ? (
                      <>
                        <span>
                          {language[lang as T].checkoutNumBol} {folio}
                        </span>
                        <span
                          className="text-end link-span"
                          onClick={printDocument}
                        >
                          {language[lang as T].checkoutDescarga}
                        </span>
                      </>
                    ) : (
                      <>
                        <span>
                          {language[lang as T].checkoutEmail}{" "}
                          <b>{buyer.email}</b>
                        </span>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md text-start">
                  <h4>{language[lang as T].checkoutRemember}</h4>
                </div>
              </div>
              <div className="row">
                <div className="col-md text-end">
                  <button
                    onClick={handleBack}
                    className="btn-form mt-2"
                    type="button"
                  >
                    {language[lang as T].checkoutInicio}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
